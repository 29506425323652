@font-face {
	font-family: "certassist-icons";
	font-display: block;
	src: url("937482f0a77c2b67182e-certassist-icons.eot?#iefix") format("embedded-opentype"),
url("937482f0a77c2b67182e-certassist-icons.woff") format("woff"),
url("937482f0a77c2b67182e-certassist-icons.woff2") format("woff2"),
url("937482f0a77c2b67182e-certassist-icons.ttf") format("truetype"),
url("937482f0a77c2b67182e-certassist-icons.svg#certassist-icons") format("svg");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: certassist-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-android:before {
	content: "\f101";
}
.icon-apple:before {
	content: "\f102";
}
.icon-chrome:before {
	content: "\f103";
}
.icon-edge:before {
	content: "\f104";
}
.icon-firefox:before {
	content: "\f105";
}
.icon-github:before {
	content: "\f106";
}
.icon-linux:before {
	content: "\f107";
}
.icon-safari:before {
	content: "\f108";
}
.icon-windows:before {
	content: "\f109";
}
.icon-caret-down:before {
	content: "\f10a";
}
.icon-caret-right:before {
	content: "\f10b";
}
.icon-code-branch:before {
	content: "\f10c";
}
.icon-download:before {
	content: "\f10d";
}
